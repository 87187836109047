import Cookies from 'js-cookie';
import { awaitTrackingConsent, ConsentType } from '../Helpers/TrackingConsentHelper';
import TestifyHelper from '../TestifyTests/TestifyHelper';

export default async function checkForRedirection(abTestPageList, pageMap, pageCountryCode, ipCountryCode) {

    const location = window.location;
    const countryCookieName = 'tradify_country';
    const countryCookieValue = Cookies.get(countryCookieName);
    const shouldCheckIp = !countryCookieValue && pageCountryCode == 'global';
    const detectedCountryCode = shouldCheckIp ? ipCountryCode : countryCookieValue;
    const countryRedirectUrl = getRedirectUrl(pageMap, detectedCountryCode);
    const variantPage = checkVariant(countryRedirectUrl);
    const testCookie = getCookie();

    const cookieOptions = {
        path: '/',
        expires: 365
    };

    if (checkRepeatedVisit())
        return handleRepeatVisit();

    if (variantPage)
        return randomRedirectToVariant(variantPage, true);

    if (countryRedirectUrl)
        return redirection(countryRedirectUrl);

    return false;

    function checkRepeatedVisit() {
        return testCookie && variantPage;
    }

    function handleRepeatVisit() {
        if (testCookie == location.pathname)
            return false;
        if (testCookie == variantPage.main_page || testCookie == variantPage.variant_page)
            return redirection(testCookie);
        if (repeatAccessDifferentCountry())
            return randomRedirectToVariant(variantPage, false);
    }

    function repeatAccessDifferentCountry() {
        const testCountry = variantPage.country_code;
        const locationCountry = (abTestPageList.find(vp => vp.main_page == window.location.pathname || vp.variant_page == window.location.pathname)).country_code;
        return testCountry == locationCountry ? true : false;
    }

    function getCookie() {
        if (abTestPageList) {
            const testVariant = abTestPageList.find(vp => vp.main_page == window.location.pathname || vp.variant_page == window.location.pathname);
            return testVariant ? Cookies.get(testVariant.test_name + '_' + testVariant.page_name) : null;
        }
        return null;
    }

    function redirection(newUrl) {

        if (setRedirectCountCookie() < 10 && newUrl != location.pathname) {
            savePageDataForGA();
            location.href = newUrl;
            return true;
        }
        savePageDataForGA();
        return false;
    }

    function setRedirectCountCookie() {
        let count = parseInt(Cookies.get("redirectCount") || 0);
        Cookies.set('redirectCount', ++count, {
            path: '/',
            expires: new Date(Date.now() + 2000)
        });
        return count;
    }

    function savePageDataForGA() {
        awaitTrackingConsent(ConsentType.Analytics, () => {
            Cookies.set('redirectedFromUrl', location.href, cookieOptions);
            Cookies.set('redirectedFromReferrer', document.referrer, cookieOptions);
        });
        awaitTrackingConsent(ConsentType.Functionality, () => {
            Cookies.set(countryCookieName, detectedCountryCode, cookieOptions);
        });
    }

    function saveVariantPageData(redirectPage, isVariationGroup) {
        awaitTrackingConsent(ConsentType.Analytics, () => {
            Cookies.set("mkt_test", variantPage.test_name + '_' + variantPage.page_name + '-' + redirectPage, cookieOptions);
            Cookies.set(variantPage.test_name + '_' + variantPage.page_name, redirectPage, cookieOptions);
            TestifyHelper.initiateTestifyForAbTest(variantPage.test_name, isVariationGroup);
        });
    }

    function checkVariant(countryRedirectUrl) {
        if (abTestPageList) {
            if (countryRedirectUrl)
                return abTestPageList.find(vp => vp.main_page == countryRedirectUrl);
            return abTestPageList.find(vp => vp.main_page == window.location.pathname || vp.variant_page == window.location.pathname);
        }
        return null;
    }

    function randomRedirectToVariant(variantPage, saveData) {
        const isVariationGroup = Math.random() > 0.5;
        const redirectPage = isVariationGroup ? variantPage.variant_page : variantPage.main_page;

        if (saveData) saveVariantPageData(redirectPage, isVariationGroup);

        return redirection(redirectPage);
    }
}

function getRedirectUrl(pageMap, detectedCountryCode) {
    if (!detectedCountryCode || detectedCountryCode == "global") return false;
    const slug = pageMap.find(pm => pm.language?.includes(detectedCountryCode))?.slug;
    if (slug) return `https://${window.location.hostname}/${slug}`;

    return false;
}
