import Cookies from 'js-cookie';

export default class DateTimeHelper {
    static getTimestamp() {
        if (this.debugTimestamp)
            return this.debugTimestamp;
            
        return Date.now();
    }
    
    static get debugTimestamp() {
        return Cookies.get('debug_timestamp');
    }
}