import TestifyTest from "./TestifyTest";
import TestifyTest316 from "./TestifyTest316";

export default class TestifyHelper {
    private static get abTestMapping(): TestifyToABTestMapping[] {
        return [
            {
                abTestName: "mkt_testify_316",
                testifyTest: new TestifyTest316(),
            },
        ];
    }

    static initiateTestifyForAbTest(testName: string, isVariation: boolean): void {
        const mapping = this.abTestMapping.find((atm) => atm.abTestName == testName);
        if (!mapping) return;

        const groupNumber = isVariation ? mapping.testifyTest.testGroups.variation : mapping.testifyTest.testGroups.control;

        mapping.testifyTest.assignGroup(groupNumber);
    }
}

type TestifyToABTestMapping = {
    abTestName: string;
    testifyTest: TestifyTest;
};
