import { dataLayer } from '../Tracking/dataLayer';
import getGtag from './../Tracking/getGtag';
const gtag = getGtag();
const pageViewEventName = 'pageview_consent_preferences_known';
const consentListeners = window.consentListeners = window.consentListeners || [];
let pageViewEventFired = false;

export function initConsentHelper(enabled) {

    if (window.location.search.includes('notracking')) return;

    window.consentFeatureEnabled = enabled;

    const timeoutSetting = enabled ? 5000 : 0;

    gtag('consent', 'default', {
        'ad_storage': getConsentValue(!window.consentFeatureEnabled),
        'analytics_storage': getConsentValue(!window.consentFeatureEnabled),
        'functionality_storage': getConsentValue(!window.consentFeatureEnabled),
        'personalization_storage': getConsentValue(!window.consentFeatureEnabled),
        'security_storage': getConsentValue(!window.consentFeatureEnabled),
        'wait_for_update': timeoutSetting
    });

    if (!window.consentFeatureEnabled)
        return sendPageViewEvent();

    initHubspotConsentListener();

    addConsentListener(consent => {

        gtag('consent', 'update', {
            'ad_storage': getConsentValue(consent.advertisement),
            'analytics_storage': getConsentValue(consent.analytics),
            'functionality_storage': getConsentValue(consent.functionality),
            'personalization_storage': getConsentValue(consent.functionality),
            'security_storage': getConsentValue(consent.necessary),
        });

        dataLayer.push({
            event: 'consent_update',
            analyticsConsent: getConsentValue(consent.analytics),
            advertisingConsent: getConsentValue(consent.advertisement),
            functionalityConsent: getConsentValue(consent.functionality),
            necessaryConsent: getConsentValue(consent.necessary),
        });

        sendPageViewEvent();
    });
}

export function awaitTrackingConsent(consentType, cb) {
    if (window.location.search.includes('notracking')) return;
    if (!window.consentFeatureEnabled) return cb();
    addConsentListener((consent) => {
        if (typeof consentType == 'string' && consent[consentType]) return cb();
        if (Array.isArray(consentType) && consentType.reduce((acc, val) => acc && consent[val], true))
            return cb();
    });
}

export const ConsentType = {
    Advertisement: 'advertisement',
    Analytics: 'analytics',
    Functionality: 'functionality',
    Necessary: 'necessary',
};

export function showCookieSettingsBanner() {
    const _hsp = window._hsp = window._hsp || [];
    _hsp.push(['showBanner']);
}

export function shouldShowCookieSettings() {
    return window.consentFeatureEnabled;
}

function addConsentListener(callback) {
    consentListeners.push(callback);
}

function getConsentValue(value) {
    return value ? 'granted' : 'denied';
}

function sendPageViewEvent() {
    if (!pageViewEventFired) {
        gtag('event', pageViewEventName);
        pageViewEventFired = true;
    }
}

function initHubspotConsentListener() {
    const _hsp = window._hsp = window._hsp || [];
    _hsp.push(['addPrivacyConsentListener', (hubspotConsentData) => {
        consentListeners.forEach((callback) => {
            callback(hubspotConsentData.categories);
        });
    }]);
}