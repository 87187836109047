import PageContext from "../Components/PageContext";
import Cookies from "js-cookie";
import { values, assign } from "lodash-es";
import DateTimeHelper from "../Helpers/DateTimeHelper";
import { awaitTrackingConsent, ConsentType } from "../Helpers/TrackingConsentHelper";
import getGtag from './../Tracking/getGtag';

export default class TestifyTest {
    context: PageContext;
    config: any;
    group: number;
    excludedReferrers: string[]
    excludedLandingPages: string[]
    excludedSources: string[]
    testGroups: { [key: string]: number };

    gtag: Gtag.Gtag;

    private readonly noGroupAssigned = -1;

    constructor(testName: string) {
        this.context = new PageContext();
        this.config = this.context.testifyTests.find((t: { name: string }) => t.name == testName);
        this.gtag = getGtag();

        if (!this.config)
            throw "Could not find test. Create a row in HubDB Testify Tests.";

        this.excludedReferrers = [];
        this.excludedLandingPages = [];
        this.excludedSources = [];

        // Default test groups
        this.testGroups = {
            control: 0,
            variation: 1,
            excludedFromTest: 2,
        }

        this.group = this.getGroupFromCookie();
    }

    private getGroupFromCookie(): number {
        const cookieValue = Cookies.get(this.config.cookieName);
        if (!cookieValue) return this.noGroupAssigned;
        return parseInt(cookieValue);
    }

    private get isGroupAssigned() {
        return this.group !== this.noGroupAssigned;
    }

    private get canAssignGroup() {
        return this.isTestRunning && !this.isGroupAssigned;
    }

    private get isTestRunning() {
        const now = DateTimeHelper.getTimestamp();
        return this.config.enabled && now > this.config.startDate && now < this.config.endDate;
    }

    private get isPreExistingCompany() {
        return this.context.isTradifyUser && !this.testsOnCompany[this.config.testName];
    }

    private get testsOnCompany() {
        return this.context.testifyDataOnCompany
            .split(",")
            .map((t: string) => t.trim())
            .map((t: string) => t.split("_"))
            .filter((t: string | any[]) => t.length === 2)
            .reduce((obj: { [x: string]: { name: any; group: any; }; }, t: any[]) => {
                obj[t[0]] = {
                    name: t[0],
                    group: t[1],
                };
                return obj;
            }, {});
    }

    private checkExcluded(): boolean {

        if (this.group == this.testGroups.excludedFromTest) return true;
        if (this.isPreExistingCompany || this.checkUrlForExclusions(location.href) || this.checkUtmTagsForExclusions()) {
            this.setGroup(this.testGroups.excludedFromTest);
            return true;
        }
        return false;
    }

    assignGroup(group: number) {
        if (!this.isTestRunning) return;
        if (this.checkExcluded() || !this.canAssignGroup) return;
        this.setGroup(group);
    }

    private checkUtmTagsForExclusions() {
        let result = false;
        const utmTags = Cookies.get("UTMTags");
        const tags = utmTags?.split(",") || [];

        tags.map((t) => {
            const urlString = decodeURIComponent(t);
            if (this.checkUrlForExclusions(urlString)) result = true;
        });

        return result;
    }

    private checkUrlForExclusions(urlString: string) {
        let result = false;
        const url = new URL(urlString);
        const source = url.searchParams.get("utm_source");
        const referrer = decodeURIComponent(url.searchParams.get("original_referrer") || "");

        this.excludedSources.map((es) => {
            if (source && source.match(es)) result = true;
        });

        this.excludedReferrers.map((er) => {
            if (referrer && referrer.match(er)) result = true;
        });

        this.excludedLandingPages.map((elp) => {
            if (urlString.match(elp)) result = true;
        });

        return result;
    }

    private setGroup(group: number) {
        this.group = group;
        this.setCookie();
        this.setGADimensions();
        this.sendTrackingEvents();
    }

    private setCookie() {
        const domain = location.host.match(/.tradifyhq.com/) ? ".tradifyhq.com" : location.host;
        const expiresIn = 1000 * 60 * 60 * 24 * 365;

        awaitTrackingConsent(ConsentType.Analytics, () => {
            Cookies.set(this.config.cookieName, this.group.toString(), {
                domain: domain,
                expires: new Date(new Date().getTime() + expiresIn),
            });
        });
    }

    private sendTrackingEvents() {
        const label = `${this.config.name}_${this.group}`;
        this.gtag("event", "Assigned Group", {
            event_category: "Testify",
            event_label: label,
            non_interaction: true,
        });
    }

    private setGADimensions() {
        if (!this.group) return;

        const testsOnCompany = assign({}, this.testsOnCompany);
        testsOnCompany[this.config.name] = {
            name: this.config.name,
            group: this.group,
        };

        const dimensions = {
            dimension12: values(testsOnCompany)
                .map((r) => `${r.name}:${r.group}`)
                .join(",")
        };

        this.gtag("set", dimensions);
    }
}
