import checkForRedirection from "./Functions/checkForRedirection";
import { initConsentHelper } from './Helpers/TrackingConsentHelper';

class DocumentHead {
    constructor(pageContext) {

        const {
            abtest_page_list,
            is_hubspot_internal_page,
            consent_feature_enabled,
            page_country_code,
            page_map,
            ip_country_code,
        } = pageContext;

        if (is_hubspot_internal_page) return;

        initConsentHelper(consent_feature_enabled);

        checkForRedirection(abtest_page_list, page_map, page_country_code, ip_country_code);

    }
}

window.initHeadScript((pageContext) => {
    new DocumentHead(pageContext);
});